<template>
  <div>
    <div class="container-inner mx-auto text-center text-gray-800 items-center">
      <div class="text-center">
        <h1 class="text-5xl font-medium text-gray-900 mb-5">
          {{ $t("pages.construction.construction") }}
        </h1>
      </div>
      <img
        class="w-full object-cover object-center block"
        src="@/assets/illustrations/under_construction.svg"
        alt="Website Under Construction"
      />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Website Under Construction",
  },
};
</script>
